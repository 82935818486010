
export const world = [
  {
    id: 5,
    cover: "https://www.lanacion.com.ar/resizer/v2/la-devaluacion-trajo-fuertes-costos-que-el-equipo-NTIFKCFGXRCGPNQBAVZKGCEZ3Y.JPG?auth=98785e2b145e8eef125b3919f7e51fb1a3c1486a797cf16f150738ed9f1b12cf&width=880&height=586&quality=70&smart=false",
    category: "ECONOMIA",
    title: "Repartir hasta lo ajeno para pagar la elevada factura de la devaluación oficial",
    authorName: "Salman",
    time: "5 Jun - 2022",
    desc: [
      {
        para1: "You audience. Least, the recently his repeat the this avarice for the have and was on would before the concise bedding were hall politely name be regretting have of even five of it the his are there again. Word seven designer far lady problem will have work with you to fully understand your business to achieve.",
      },
      {
        para2: "We work with clients big and small across a range of sectors and we utilize all forms of media to get your name out there in a way that’s right for you. We believe that analysis of your company and your customers is key in responding effectively to your promotional needs and we will work with you.",
      },
      {
        para3: "We have a number of different teams within our agency that specialise in different areas of business so you can be sure that you won’t receive a generic service and although we can’t boast years and years of service we can ensure you that is a good thing in this industry.",
      },
    ],
    details: [
      {
        title: "Starting a new company is easy",
      },
      { para1: "Our teams are up to date with the latest technologies, media trends and are keen to prove themselves in this industry and that’s what you want from an advertising agency, not someone who is relying on the same way of doing things that worked 10 years, 5 years or even a year ago." },
      {
        quote: "Scarfs, still not this no with explains it me and option on the any options roasted when I and state can that an don't subjective of has his take on and in from royal everything took raising our have behind success you the mechanic.",
      },
      {
        para2: "And, higher by agency; In from their in and we spirit, through merely small him sounded a all now, with that put gift white highly geared that was left back as of or logged important. A over have the large try understanding the believe. Perfected been viewer. Shreds early willingly safely what passion the.",
      },
      {
        para3: "In an ideal world this website wouldn’t exist, a client would acknowledge the importance of having web copy before the design starts. Needless to say it’s very important, content is king and people are beginning to understand that. However, back over in reality some project schedules and budgets don’t allow for web copy to be written before the design phase, this is sad but true.",
      },
    ],
  },
  {
    id: 6,
    cover: "../images/world/world2.jpg",
    category: "",
    title: "Renewablee91 ener553gy dead industry waits for Policya ",
    authorName: "Salmana",
    time: "5 Jun - 2022",
    desct: "If rather, him the would treble-range wild any long of policy waved preparations disguised first initial that commitment had my in for spirits gradual solitary I for his absolutely with it and I to unable follow the to as every it the control and a of thinks the what's help days, with and paint, series at window and could we've offer, hard with king either written dry be are of princesses. We’re here to help, we’ve written examples of web copy for over 40 industries for you to use at concept phase of your projects to bring a little life and realism to your designs and help you think about who and what you are designing for. We want clients and designers alike to think about their design and how it will work with the web copy, we want you think about how numbers, symbols and bullet points will look. Sure, it's easier to just run down the forms and fill the fields with garbage in order to plow through them quickly. But that's not real. That's not what your customers are going to do. Is it really smart to take a shortcut when customers are forced to take the long road? When you just enter fake copy in rapid-fire fashion, you don't know what it really feels like to fill out that form. Do as your customers do and you'll understand them better. When you understand them better, and feel what they feel, you'll build a better interface.",
  },
  {
    id: 7,
    cover: "../images/world/world3.jpg",
    category: "",
    title: "Mount Etna erupts fashion in nice12 looking pictures ",
    authorName: "Salman",
    time: "5 Jun - 2022",
  },
  {
    id: 8,
    cover: "../images/world/world2.jpg",
    category: "",
    title: "China asks Canada to add their full plan ",
    authorName: "Salman",
    time: "5 Jun - 2022",
  },
  {
    id: 9,
    cover: "../images/world/world2.jpg",
    category: "",
    title: "Serena secures comeback win against Ivanovic",
    authorName: "Salman",
    time: "5 Jun - 2022",
  },
]

export const ppost = [
  {
    "id": 26,
    "cover": "https://www.diarioellibertador.com.ar/wp-content/uploads/2024/12/04-FOTO-40-Gentileza-4.jpg",
    "category": "POLÍTICA",
    "title": "Vischi: «La expulsión de Kueider es un mensaje necesario para el Senado»",
    "date": "17 Dic - 2024",
    "desc": [
      {
        "para1": "El senador nacional de Corrientes por la UCR, Eduardo «Peteco» Vischi, expresó con contundencia su apoyo a la expulsión del ex senador Edgardo Kueider del Senado. Vischi argumentó que las acusaciones contra Kueider justifican plenamente su salida de la Cámara alta, subrayando la importancia de mantener la integridad institucional."
      },
      {
        "para2": "Durante su intervención, Vischi criticó la doble moral de ciertos sectores políticos que, según él, actúan como una «corporación» para proteger a sus propios miembros, mientras sancionan severamente a quienes cometen irregularidades. Además, cuestionó la actitud de la oposición hacia los medios de comunicación, denunciando una postura hipócrita y oportunista."
      },
      {
        "para3": "La votación para la expulsión de Kueider culminó con 60 votos a favor, 6 en contra y una abstención, enviando un fuerte mensaje en favor de la transparencia y la ética en el Senado. En contraste, se rechazó la suspensión del senador Oscar Parrilli, argumentando que no se cumplían los requisitos constitucionales para tal medida."
      }
    ],
    "details": [
      {
        "title": "Crítica a la Hipocresía Política"
      },
      {
        "para1": "Vischi dirigió parte de su discurso a criticar la postura de quienes defienden a Kueider, señalando una contradicción en su relación con los medios de comunicación. «Hace muy poco decían que Clarín miente, ¿se acuerdan? En aquel momento, los medios que hablaban mal de ellos mentían. Hoy, sin embargo, toman como verdad absoluta lo que dicen esos mismos medios», denunció, acusando a la oposición de actuar de manera oportunista y acomodaticia."
      },
      {
        "para1": "El senador también destacó que no solo los actos atribuidos a Kueider dañan la credibilidad del Senado, sino también «la hipocresía de los últimos 20 años» de un sector político que, según él, se ha mostrado indiferente ante múltiples casos de corrupción. «Se hicieron los distraídos hasta teniendo a la Presidenta de este cuerpo condenada», afirmó, refiriéndose a Cristina Fernández de Kirchner y su situación judicial."
      },
      {
        "title": "Kueider sí, Parrilli no"
      },
      {
        "para1": "La votación para la expulsión del legislador Edgardo Kueider, quien se encuentra detenido en Paraguay por ingresar al país con 200.000 dólares sin declarar, resultó en 60 votos a favor, 6 en contra y una abstención. Este resultado refleja un firme compromiso por parte del Senado para sancionar comportamientos que comprometen su integridad."
      },
      {
        "para1": "En contraste, la propuesta de suspensión del senador Oscar Parrilli fue rechazada, ya que no se consideraron cumplidos los requisitos establecidos en la Constitución Nacional para tal medida. Este contraste subraya una postura diferenciada dentro del cuerpo legislativo respecto a las sanciones internas."
      },
      {
        "title": "Un Llamado a la Transparencia"
      },
      {
        "para1": "Vischi enfatizó que la expulsión de Kueider debe interpretarse como un mensaje claro en favor de la transparencia y la ética en el ámbito legislativo. «Es necesario que el Senado actúe con contundencia frente a estos casos, no sólo para sancionar a quienes han cometido irregularidades, sino también para demostrar que este cuerpo no tolerará más hipocresías ni encubrimientos», concluyó."
      },
      {
        "para1": "Además, destacó la importancia de recuperar la confianza en las instituciones y establecer límites firmes frente a comportamientos que comprometen la integridad del Senado. En un contexto donde la ética política está bajo constante escrutinio por parte de los ciudadanos, Vischi subrayó la necesidad de mantener altos estándares de conducta dentro del cuerpo legislativo."
      }
    ]
  }
  
  ,
  {
    "id": 27,
    "cover": "https://www.diarioellibertador.com.ar/wp-content/uploads/2024/08/FOTO-202-MUNICIPALIDAD.jpg",
    "category": "ECONOMÍA",
    "title": "Tassano confirmó la fecha de pago del aguinaldo para agentes municipales",
    "date": "17 Dic - 2024",
    "desc": [
      {
        "para1": "El intendente Eduardo Tassano anunció la fecha de inicio del pago de aguinaldo para los trabajadores del Municipio durante una entrevista con un medio radial."
      },
      {
        "para2": "Tassano detalló que el cronograma de pago se extenderá entre el miércoles 18 y el viernes 20. Recordó que, como se había anunciado previamente, los agentes recibirán un bono de fin de año de 300 mil pesos, distribuido en tres tramos."
      },
      {
        "para3": "Además, el intendente adelantó que tanto el plus de refuerzo como el plus especial que se abonan mensualmente a los trabajadores de la Municipalidad pasarán a tener aportes. Explicó que “los pluses pasarán a tener aportes y contribuciones”, señalando que esta medida es una idea del contador, su secretario de Hacienda, Guillermo Corrales, y que se proyectará y calculará en la medida en que el presupuesto esté equilibrado."
      }
    ],
    "details": [
      {
        "title": "Cronograma de Pago del Aguinaldo"
      },
      {
        "para1": "El intendente confirmó que el pago del aguinaldo para los agentes municipales se realizará entre el miércoles 18 y el viernes 20 de diciembre. Este bono de fin de año, valorado en 300 mil pesos, se distribuirá en tres tramos para facilitar su gestión y recepción por parte de los trabajadores."
      },
      {
        "title": "Aportes a Pluses"
      },
      {
        "para1": "Tassano adelantó que tanto el plus de refuerzo como el plus especial que se otorgan mensualmente a los trabajadores de la Municipalidad pasarán a incluir aportes y contribuciones. Esta modificación, según el intendente, es una iniciativa propuesta por su secretario de Hacienda, Guillermo Corrales."
      },
      {
        "para1": "El jefe comunal explicó que “es una idea del contador”, haciendo referencia a la propuesta de incorporar aportes a los pluses. Añadió que “podemos proyectar y calcular en la medida en la que tenemos un presupuesto equilibrado”, indicando que estas modificaciones dependerán de la disponibilidad presupuestaria del Municipio."
      }
    ]
  }
  
  ,
  {
    id: 28,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/10/05-FOTO-50-Gentileza-5.jpg',
    category: "ECONOMÍA",
    title: "Valdés llamó a superar el debate parlamentario y salir al territorio",
    date: "22 Oct - 2024",
    desc: [
      {
        para1: "22/10/2024 - Durante la apertura de la Exposición Rural de Ituzaingó, el gobernador Gustavo Valdés instó a impulsar políticas que promuevan el crecimiento económico en Corrientes, destacando la calidad del ganado bovino e invitando al sector privado a invertir en la industria."
      },
      {
        para2: "Valdés subrayó la necesidad de invertir en mejoras genéticas y en infraestructura rural para potenciar la producción ganadera y combatir la pobreza, enfatizando que el desarrollo debe trascender los debates del Congreso."
      }
    ],
    details: [
      {
        title: "Impulso a la ganadería y la industria"
      },
      {
        para1: "El Gobernador resaltó la importancia de la calidad genética del ganado correntino y expresó su expectativa de que los productores obtengan buenos precios. Corrientes cuenta con 26,000 ganaderos activos y busca mejorar la eficiencia a través del Plan Pastura."
      },
      {
        title: "Fortalecimiento de la infraestructura rural"
      },
      {
        para1: "Valdés enfatizó la necesidad de mejorar caminos rurales y apoyar a organizaciones comunitarias, destacando que el desarrollo debe orientarse hacia la producción e industrialización como vía para enfrentar la pobreza."
      },
      {
        title: "Incremento de la inversión privada"
      },
      {
        para1: "El mandatario invitó al sector privado a intensificar su participación en la industria provincial, destacando la potencialidad de Corrientes en la ganadería y el desarrollo rural. La nueva sede inaugurada fue descrita como símbolo del progreso regional."
      },
      {
        title: "Respaldo financiero y desarrollo productivo"
      },
      {
        para1: "Valdés anunció un apoyo de 3.000 millones de pesos para proyectos productivos y resaltó la importancia de la producción de búfalos en Caá Catí, con 70,000 cabezas, y el interés de China en productos bubalinos."
      },
      {
        title: "Conservación de la tradición ganadera"
      },
      {
        para1: "El Gobernador destacó la relevancia de mantener la tradición ganadera correntina, orientando la producción local hacia un crecimiento sostenido a largo plazo. La ubicación estratégica de Ituzaingó busca posicionar a Corrientes como referente en genética y producción."
      }
    ]
  }
  ,
  {
    id: 29,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/10/TERESA.jpg',
    category: "POLÍTICA",
    title: "La interventora del PJ correntino criticó a Kicillof",
    date: "17 Oct - 2024",
    desc: [
      {
        para1: "17/10/2024 - La senadora bonaerense María Teresa García criticó en Radio 750 a algunos dirigentes peronistas que han «embarrado» las internas del Partido Justicialista (PJ), señalando especialmente al gobernador Axel Kicillof y al mandatario riojano Ricardo Quintela."
      },
      {
        para2: "El PJ realizará elecciones el 17 de noviembre para definir su nueva conducción, en medio de divisiones pese al llamado de unidad de Cristina Fernández de Kirchner."
      }
    ],
    details: [
      {
        title: "Críticas a Kicillof y Quintela"
      },
      {
        para1: "García, exministra de Gobierno de Kicillof, cuestionó la falta de pronunciamiento del gobernador sobre las internas del PJ. Afirmó que alguien tan cercano a Cristina debería haber expresado su posición."
      },
      {
        para2: "La senadora acusó a Quintela de entorpecer el proceso interno, destacando que, a pesar de su promesa de retirar su candidatura si Cristina se presentaba, esto no ocurrió."
      },
      {
        title: "Clima de conflicto"
      },
      {
        para3: "García advirtió sobre un ambiente de odio y enojo en el peronismo y enfatizó que el partido necesita un rumbo claro. Concluyó pidiendo reflexión a Kicillof, señalando que algunas personas de su entorno no serían la mejor influencia."
      }
    ]
  }
  
  
]


export const lifestyle = [
  {
    id: 30,
    category: "INTERIOR",
    title: "Amplio operativo para la celebración del Gauchito Gil.",
    date: "05. Enero 2024",
    comments: 0,
    cover: "https://www.diarioepoca.com/content/bucket/0/1340360w762h476c.jpg.webp",
  },
  {
    id: 31,
    category: "CORRIENTES",
    title: "Día picante en Diputados: 'Empezó mal el año', advirtió Jorge Romero",
    date: "05. Enero 2024",
    cover: "https://www.diarioepoca.com/content/bucket/6/1340346w380h179c.jpg.webp",
  },
  {
    id: 32,
    category: "CORRIENTES",
    title: "Largas filas en plena Ruta 12 para comprar una garrafa de gas a $5.000",
    date: "03. Enero 2024",
    cover: "https://www.diarioepoca.com/content/bucket/2/1340352w762h564c.jpg.webp",
  },
  {
    id: 33,
    category: "CORRIENTES",
    title: "La Provincia compró el predio de la tabacalera que cerró en Goya",
    date: "17. Septiembre 2023",
    cover: "https://www.nortecorrientes.com/content/bucket/8/369968w380h220c.jpg.webp",
  },
]
export const tpost = [
  {
    id: 34,
    title: "Radio Sudamericana",
    cover: "https://cdn.webrad.io/images/logos/radioarg-com/sudamericana-corrientes.png",
    href: "https://envivo.radiosudamericana.com/inc.popup-reproductor.php?popup=true"
  },
  {
    id: 35,
    title: "Radio Dos",
    cover: "https://cdn.webrad.io/images/logos/radioarg-com/radio-dos-corrientes.png",
    href: "https://www.radiodos.com.ar/radio.php"
  },
  {
    id: 36,
    title: "LT7",
    cover: "https://cdn.webrad.io/images/logos/radioarg-com/lt7-900-corriente.png",
    href: "https://www.lt7noticias.com/radio.php"
  },
]
export const gallery = [
  {
    cover: "../images/gallery/g1.jpg",
  },
  {
    cover: "../images/gallery/g2.jpg",
  },
  {
    cover: "../images/gallery/g3.jpg",
  },
  {
    cover: "../images/gallery/g4.jpg",
  },
  {
    cover: "../images/gallery/g5.jpg",
  },
]
export const discover = [
  {
    title: "gameñss3a22aaaaaaadasa2s",
    cover: "../images/discover/d1.jpg",
  },
  {
    title: "DEPORTES",
    cover: "../images/discover/d2.jpg",
  },
  {
    title: "humaouaaaaAaArr",
    cover: "../images/discover/d3.jpg",
  },
  {
    title: "gadgets",
    cover: "../images/discover/d4.jpg",
  },
  {
    title: "movieees",
    cover: "../images/discover/d5.jpg",
  },
  {
    title: "niten3aa2asdkaiak25aaaaaaaaaassaadauvaaao!",
    cover: "../images/discaaover/d6.jpg",
  },
]

